import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ChangePasswordPayload, ForgotPayload, LoginPayload, LoginResponse, ResetPasswordPayload, ResetPasswordResponse, VerifyOtpPayload, VerifyOtpResponse, logoutResponse } from '../models/auth.model';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonResponse } from '../models/commonResponse.model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  emailSubject: BehaviorSubject<ForgotPayload | null> =
    new BehaviorSubject<ForgotPayload | null>(null);

  profileDetails: BehaviorSubject<LoginResponse | null> =
    new BehaviorSubject<LoginResponse | null>(null);

  resetTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();
  toggleSidebarView(): void {
    this.isOpenSubject.next(!this.isOpenSubject.value);
  }

  constructor(
    private readonly http: HttpClient,
    private _translateService: TranslateService
  ) {}

  isAuthenticated(): boolean {
    if (
      localStorage.getItem(environment.storageKey) &&
      localStorage.getItem(environment.storageKey) !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  loginUser(loginDetails: LoginPayload): Observable<LoginResponse> {
    return this.http
      .post<{
        data: LoginResponse;
      }>(`${environment.base_url}login`, loginDetails)
      .pipe(map(res => res.data));
  }

  forgotPassword(email: ForgotPayload): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${environment.base_url}forgotPassword`,
      email
    );
  }

  verifyOtp(otp: VerifyOtpPayload): Observable<VerifyOtpResponse> {
    return this.http
      .post<{
        data: VerifyOtpResponse;
      }>(`${environment.base_url}verifyOtp`, otp)
      .pipe(map(res => res.data));
  }

  resetPassword(data: ResetPasswordPayload): Observable<ResetPasswordResponse> {
    return this.http
      .post<ResetPasswordResponse>(`${environment.base_url}setPassword`, data)
      .pipe(map(res => res));
  }

  resetPasswordUser(data: ResetPasswordPayload): Observable<ResetPasswordResponse> {
    return this.http
      .post<ResetPasswordResponse>(`${environment.base_url_user}resetPassword`, data)
      .pipe(map(res => res));
  }

  getProfile(): Observable<LoginResponse> {
    return this.http
      .get<{ data: LoginResponse }>(`${environment.base_url}profile`)
      .pipe(map(res => res.data));
  }

  updateProfile(data: LoginResponse): Observable<LoginResponse> {
    return this.http
      .put<{
        data: LoginResponse;
      }>(`${environment.base_url}updateProfile`, data)
      .pipe(map(res => res.data));
  }

  changePassword(data: ChangePasswordPayload): Observable<CommonResponse> {
    return this.http
      .post<CommonResponse>(`${environment.base_url}changePassword`, data)
      .pipe(map(res => res));
  }

  logout(): Observable<logoutResponse> {
    return this.http.get<logoutResponse>(`${environment.base_url}logout`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoutAlert(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this._translateService.instant('AreYouSure'),
        text: this._translateService.instant('Youwillbeloggedout'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this._translateService.instant('Cancel'),
        confirmButtonColor: '#ffaf18',
        cancelButtonColor: '#000',
        confirmButtonText: this._translateService.instant('Logout')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }

  passwordChangedLogout(): Promise<any> {
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this._translateService.instant('PasswordUpdatedSuccessfully'),
        text: this._translateService.instant('Youwillbeloggedout'),
        icon: 'success'
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }
}